import React, { Component } from "react";
import img from "../../assets/images/SertifikatAri.png";

export default class TeamDetailsContent extends Component {
  expertise = {
    items: [
      {
        list:
          "Melaksanakan Audit SMK3 sesuai dengan ketentuan peraturan perundang-undangan.",
      },
      {
        list:
          "Merahasiakan hasil Audit SMK3 kepada pihak-pihak yang tidak berkepentingan.",
      },
      {
        list: "Mematuhi peraturan K3 di tempat kerja.",
      },
      {
        list:
          "Memberikan penilaian dan laporan Audit SMK3 yang dapat dipertanggung jawabkan.",
      },
      {
        list: "Melaksanakan proses Audit secara independent dan professiona.",
      },
      {
        list:
          "Menghentikan pelaksanaan Audit SMK3 apabila belum ada system yang dibangun.",
      },
    ],
  };
  educations = {
    items: [
      {
        list:
          "Ahli Keselamatan dan Kesehatan Kerja, Disertifikasi oleh PT. Indohes Magna Persada",
      },
    ],
  };
  render() {
    return (
      <section className="team-details-wrapper">
        <div className="container">
          <div className="team-details-grid-top d-grid">
            <div className="team-details-img">
              <img src={img} alt="Team Details" />
            </div>
            <div className="team-details-content">
              <h1 className="name">Ari Bowo</h1>
              <span className="skills">
                Auditor Ahli Kesehatan dan Keselamatan Kerja
              </span>

              <div className="team-expertise-education-grid d-grid">
                <div className="left">
                  <h2>Peran dan Tanggung Jawab</h2>
                  <ul className="team-details-expertise lists">
                    {this.expertise.items.map((item, index) => {
                      return <li key={index}>{item.list}</li>;
                    })}
                  </ul>
                </div>
                <div className="right">
                  <h2>Sertifikasi</h2>
                  <ul className="team-details-education lists">
                    {this.educations.items.map((item, index) => {
                      return <li key={index}>{item.list}</li>;
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
