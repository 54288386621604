import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

export default function BlogFeatures({blog}) {
  const {title, button, date, author, images, description} = blog;

  return (
    <div className='blog' style={{maxWidth: 'fit-content'}}>
      <div className='blog-img-box'>
        <img src={images[0]} alt='Blog' />
      </div>
      <div className='blog-content-box'>
        <span className='blog-meta'>
          {date} By {author}
        </span>

        <h2 className='blog-title'>{title}</h2>

        <p className='blog-desc'>{description}</p>
        <div className='blog-footer'>
          <Link className='theme-button' to='/contact'>
            {button}
          </Link>
        </div>
      </div>
    </div>
  );
}

BlogFeatures.propTypes = {
  blog: PropTypes.shape({
    title: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.string).isRequired,
    description: PropTypes.string.isRequired,
  }),
};
