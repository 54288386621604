import img1 from "./assets/images/onlineTraining.jpeg";
import img2 from "./assets/images/img3.jpg";
import img3 from "./assets/images/img3.jpg";
import img4 from "./assets/images/img31.jpg";

export default [
  {
    fields: {
      id: "1",
      title: "Pembinaan Calon Ahli K3 Umum Kemenaker RI",
      date: "20 Aug, 2020",
      author: "Mitra Satu Rupa",
      authorurl: "",
      button: "Contact Kami",
      commentnum: "2",
      type: "full",
      featured: true,
      description:
        "Mari segera bergabung dalam event ini dimana para peserta dapat mendapatkan pelatihan oleh anak perusahaan kami PT. MITRA SERASI MAJU dengan para pembina yang ahli dalam bidangnya untuk menjadikan para peserta Ahli K3 Umum dengan sertifikasi yang di keluarkan oleh Kemenaker RI, event ini akan diadakan secara online melalui aplikasi Zoom dan akan berlangsung sejak 20 - 31 Agustus 2020.",
      fulldesc:
        "Mari segera bergabung dalam event ini dimana para peserta dapat mendapatkan pelatihan oleh tim Mitra Satu Rupa agar menjadi Ahli K3 Umum dengan sertifikasi yang di keluarkan oleh Kemenaker RI",
      images: [
        {
          fields: {
            file: {
              url: img1,
            },
          },
        },
        {
          fields: {
            file: {
              url: img4,
            },
          },
        },
      ],
    },
  },
  {
    fields: {
      id: "2",
      title: "Strategy for Norway's Pesion Fund Global",
      date: "25 Jan, 2020",
      author: "TechyDevs",
      authorurl: "https://techydevs.com",
      button: "Read More",
      commentnum: "1",
      type: "full",
      featured: false,
      description:
        "Investig ationes demons trave runt lectores legere liusry quod ii legunt saepius",
      fulldesc:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore Phasellus viverra nisl vitae cursus aei uismod suspendisse saepius claritas investig.",
      images: [
        {
          fields: {
            file: {
              url: img1,
            },
          },
        },
        {
          fields: {
            file: {
              url: img4,
            },
          },
        },
      ],
    },
  },
  {
    fields: {
      id: "3",
      title: "We are hiring many developer to develop",
      date: "14 Fab, 2020",
      author: "TechyDevs",
      authorurl: "https://techydevs.com",
      button: "Read More",
      commentnum: "4",
      type: "grid",
      featured: false,
      description:
        "Investig ationes demons trave runt lectores legere liusry quod ii legunt saepius",
      fulldesc:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore Phasellus viverra nisl vitae cursus aei uismod suspendisse saepius claritas investig.",
      images: [
        {
          fields: {
            file: {
              url: img1,
            },
          },
        },
        {
          fields: {
            file: {
              url: img4,
            },
          },
        },
      ],
    },
  },
  {
    fields: {
      id: "4",
      title: "Main reasons to explan fast business builder",
      date: "20 Mar, 2020",
      author: "TechyDevs",
      authorurl: "https://techydevs.com",
      button: "Read More",
      commentnum: "0",
      type: "grid",
      featured: false,
      description:
        "Investig ationes demons trave runt lectores legere liusry quod ii legunt saepius",
      fulldesc:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore Phasellus viverra nisl vitae cursus aei uismod suspendisse saepius claritas investig.",
      images: [
        {
          fields: {
            file: {
              url: img1,
            },
          },
        },
      ],
    },
  },
  {
    fields: {
      id: "5",
      title: "Blackpool polices hunt for David Schwimmer",
      date: "23 Mar, 2020",
      author: "TechyDevs",
      authorurl: "https://techydevs.com",
      button: "Read More",
      commentnum: "0",
      type: "grid",
      featured: false,
      description:
        "Investig ationes demons trave runt lectores legere liusry quod ii legunt saepius",
      fulldesc:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore Phasellus viverra nisl vitae cursus aei uismod suspendisse saepius claritas investig.",
      images: [
        {
          fields: {
            file: {
              url: img2,
            },
          },
        },
      ],
    },
  },
  {
    fields: {
      id: "6",
      title: "Business Structured are changed by Minzel",
      date: "23 Mar, 2020",
      author: "TechyDevs",
      authorurl: "https://techydevs.com",
      button: "Read More",
      commentnum: "0",
      type: "grid",
      featured: false,
      description:
        "Investig ationes demons trave runt lectores legere liusry quod ii legunt saepius",
      fulldesc:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore Phasellus viverra nisl vitae cursus aei uismod suspendisse saepius claritas investig.",
      images: [
        {
          fields: {
            file: {
              url: img3,
            },
          },
        },
      ],
    },
  },
];
