import React, { Component } from "react";

export default class PortfolioDetailsContent extends Component {
  render() {
    return (
      <section className="portfolio-details-wrapper">
        <div className="container">
          <div className="portfolio-details-image-box">
            <div className="portfolio-details-content">
              <h1>Definisi SMK3</h1>
              <p>
                SMK 3 Menurut <b>Peraturan Pemerintah No. 50 tahun 2012</b>,
                Sistem Manajemen Keselamatan dan Kesehatan Kerja (SMK3) adalah
                bagian dari sistem manajemen keseluruhan yang meliputi struktur
                organisasi, perencanaan, tanggungjawab, pelaksanaan, prosedur,
                proses dan sumberdaya yang dibutuhkan bagi pengembangan,
                penerapan, pencapaian, pengkajian dan pemeliharaan kebijakan
                Keselamatan dan kesehatan kerja dalam rangka pengendalian risiko
                yang berkaitan dengan kegiatan kerja guna terciptanya tempat
                kerja yang aman, efisien dan produktif.
              </p>
              <p>
                Tujuan dan sasaran sistem Manajemen K3 :
                <br />
                &nbsp;&nbsp;<b>a.</b> &nbsp;&nbsp;Meningkatkan efektifitas
                perlindungan keselamatan dan Kesehatan kerja yang terencana,
                terstruktur, dan terintegrasi
                <br />
                &nbsp;&nbsp;<b>b.</b> &nbsp;&nbsp;Mencegah dan mengurangi
                kecelakaan kerja dan penyakit akibat kerja dengan melibatkan
                seluruh elemen di perusahaan
                <br />
                &nbsp;&nbsp;<b>c.</b> &nbsp;&nbsp;Menciptakan tempat kerja yang
                aman, nyaman, dan efisien untuk mendorong produktivitas.
                <br />
              </p>
              <h1>Mengapa kita harus menerapkan SMK3 ?</h1>
              <p>
                &nbsp;&nbsp;<b>•</b> &nbsp;&nbsp;Kewajiban dan amanah peraturan
                perundangan (UU No. 1 Tahun 1970, UU No. 13 Tahun 2003, dan PP
                No. 50 Tahun 2012)
                <br />
                <br />
                Tanggung jawab pengusaha untuk menyediakan tempat kerja yang
                aman bagi pekerjanya tuntutan pemerintah, masyarakat, pasar,
                atau dunia internasional Penerapan SMK3 juga mempunyai banyak
                manfaat bagi industri kita antara lain :
              </p>
              <p>
                <b>Manfaat Langsung :</b>
                <br />
                - Mengurangi jam kerja yang hilang akibat kecelakaan kerja.
                <br />
                - Menghindari kerugian material dan jiwa akibat kecelakaan
                kerja.
                <br />- Menciptakan tempat kerja yang efisien dan produktif
                karena tenaga kerja merasa aman dalam bekerja.
              </p>
              <p>
                <b>Manfaat Tidak Langsung :</b>
                <br />
                - Meningkatkan image market terhadap perusahaan.
                <br />
                - Menciptakan hubungan yang harmonis bagi karyawan dan
                perusahaan.
                <br />- Perawatan terhadap mesin dan peralatan semakin baik,
                sehingga membuat umur alat semakin lama.
              </p>
              <h1>Elemen pada SMK3</h1>
              <p>
                Sebagai mana terdapat pada
                <b> Peraturan Pemerintah No. 50 tahun 2012 </b> sebagai berikut:
              </p>
              <p>
                <b>1.&nbsp; Komitmen dan Kebijakan</b>
                <br />
                &nbsp;&nbsp;<b>1.1.</b> &nbsp;&nbsp;&nbsp;&nbsp;Kepemimpinan dan
                Komitmen
                <br />
                &nbsp;&nbsp;<b>1.2.</b> &nbsp;&nbsp;&nbsp;Tinjauan Awal K3{" "}
                <br />
                &nbsp;&nbsp;<b>1.3.</b> &nbsp;&nbsp;&nbsp;Kebijakan K3 <br />
                <br />
                <b>2.&nbsp; Peraturan Perundangan dan Persyaratan Lainnya</b>
                <br />
                &nbsp;&nbsp;<b>2.1.</b> &nbsp;&nbsp;&nbsp;&nbsp;Perencanaan
                Identifikasi Bahaya, Penilaian dan Pengendalian Resiko
                <br />
                &nbsp;&nbsp;<b>2.2.</b> &nbsp;&nbsp;&nbsp;Peraturan Perundangan
                dan Persyaratan Lainnya <br />
                &nbsp;&nbsp;<b>2.3.</b> &nbsp;&nbsp;&nbsp;Tujuan dan Sasaran{" "}
                <br />
                &nbsp;&nbsp;<b>2.4.</b> &nbsp;&nbsp;&nbsp;Indikator Kinerja{" "}
                <br />
                &nbsp;&nbsp;<b>2.5.</b> &nbsp;&nbsp;&nbsp;Perencanaan Awal dan
                Perencanaan Kegiatan yang Sedang Berlangsung <br />
                <br />
                <b>3.&nbsp; Penerapan</b>
                <br />
                &nbsp;&nbsp;<b>3.1.</b> &nbsp;Jaminan Kemampuan
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;<b>3.1.1.</b> &nbsp;&nbsp;&nbsp;SDM,
                Sarana dan Dana <br />
                &nbsp;&nbsp;&nbsp;&nbsp;<b>
                  3.1.2
                </b> &nbsp;&nbsp;&nbsp;Integrasi <br />
                &nbsp;&nbsp;&nbsp;&nbsp;<b>3.1.3</b> &nbsp;&nbsp;&nbsp;Tanggung
                Jawab dan Tanggung Gugat <br />
                &nbsp;&nbsp;&nbsp;&nbsp;<b>3.1.4</b>{" "}
                &nbsp;&nbsp;&nbsp;Konsultasi, Motivasi dan Kesadaran
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;<b>3.1.5</b> &nbsp;&nbsp;&nbsp;Pelatihan
                dan Kompensasi
                <br />
                <br />
                &nbsp;&nbsp;<b>3.2.</b> &nbsp;Kegiatan Pendukung
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;<b>3.2.1.</b>{" "}
                &nbsp;&nbsp;&nbsp;Komunikasi <br />
                &nbsp;&nbsp;&nbsp;&nbsp;<b>
                  3.2.2
                </b> &nbsp;&nbsp;&nbsp;Pelaporan <br />
                &nbsp;&nbsp;&nbsp;&nbsp;<b>3.2.3</b>{" "}
                &nbsp;&nbsp;&nbsp;Pendokumentasian <br />
                &nbsp;&nbsp;&nbsp;&nbsp;<b>3.2.4</b>{" "}
                &nbsp;&nbsp;&nbsp;Pengendalian Dokumen
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;<b>3.2.5</b>{" "}
                &nbsp;&nbsp;&nbsp;Pencatatan dan Manajemen Informasi
                <br />
                <br />
                &nbsp;&nbsp;<b>3.3.</b> &nbsp;Identifikasi Sumber Bahaya,
                Penilaian dan Pengendalian Resiko
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;<b>3.3.1.</b>{" "}
                &nbsp;&nbsp;&nbsp;Identifikasi Sumber Bahaya <br />
                &nbsp;&nbsp;&nbsp;&nbsp;<b>3.3.2</b> &nbsp;&nbsp;&nbsp;Penilaian
                Resiko <br />
                &nbsp;&nbsp;&nbsp;&nbsp;<b>3.3.3</b> &nbsp;&nbsp;&nbsp;Tindakan
                Pengendalian
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;<b>3.3.4</b>{" "}
                &nbsp;&nbsp;&nbsp;Perancangan dan Rekayasa
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;<b>3.3.5</b>{" "}
                &nbsp;&nbsp;&nbsp;Pengendalian Administratif
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;<b>3.3.6</b> &nbsp;&nbsp;&nbsp;Tinjauan
                Ulang Kontrak
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;<b>3.3.7</b> &nbsp;&nbsp;&nbsp;Pembelian
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;<b>3.3.8</b> &nbsp;&nbsp;&nbsp;Prosedur
                Menghadapi keadaan darurat dan Bencana
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;<b>3.3.9</b> &nbsp;&nbsp;&nbsp;Prosedur
                Menghadapi Insiden
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;<b>3.3.10</b> &nbsp;Prosedur Rencana
                Pemulihan Keadaan Darurat
                <br />
                <br />
                <b>4.&nbsp; Pengukuran dan Evaluasi</b>
                <br />
                &nbsp;&nbsp;<b>4.1.</b> &nbsp;&nbsp;&nbsp;&nbsp;Inspeksi dan
                Pengujian
                <br />
                &nbsp;&nbsp;<b>4.2.</b> &nbsp;&nbsp;&nbsp;Audit SMK3 <br />
                &nbsp;&nbsp;<b>4.3.</b> &nbsp;&nbsp;&nbsp;Tindakan Perbaikan dan
                Pencegahan <br />
                <br />
                <b>
                  5.&nbsp; Tinjauan Ulang dan Peningkatan oleh Pihak Manajemen
                </b>
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
